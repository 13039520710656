import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FOOTER_FOREGROUND } from "../../../../templates/strings";

const TemplateTwoBackground = styled.div`
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-image: url(${({ bgImg }) => bgImg});
  font-family: "Roboto", sans-serif !important;
`;
const WideContainer = styled.div`
  padding: 20px 0;
  max-width: 1120px;
`;
const InnerContainer = styled.div`
  padding: 0 20px;
  padding-bottom: 20px;
  @media (max-width: 481px) {
    padding: 0;
  }
`;
const CenterContent = styled.div`
  padding: 0 20px;
  &::before {
    // to avoid opacity occurance on childrens
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: ${({ bg }) => bg};
    opacity: ${({ opacity }) => opacity};
    border-radius: 5px !important;
  }
  @media (max-width: 481px) {
    padding: 0 10px;
  }
`;
const Header = styled.div`
  padding: 20px 0;
`;
const HeaderRow = styled(Row)`
  margin-top: 20px;
  padding: 0 10px;
`;
const HeaderImg = styled.img`
  width: 200px;
  max-width: 100%;
`;
const PhNumHeadline = styled.div`
  font-size: 23px;
  line-height: normal;
  color: ${({ color }) => color};
`;
const PhNumLink = styled.a`
  font-size: 24px;
  line-height: 1.3em;
  text-decoration: none;
  color: ${({ color }) => color};
  &:hover {
    color: ${({ color }) => color};
    text-decoration: underline;
  }
  @media (max-width: 481px) {
    font-size: 22px;
  }
`;
const MarginTopContainer = styled.div`
  margin-top: 30px;
`;
const ContactUsBtn = styled.button`
  font-size: 20px;
  padding: 10px 25px;
  background: transparent;
  color: ${({ hoverColor }) => hoverColor};
  border: ${({ hoverColor }) => `3px solid ${hoverColor}`};
  &:hover {
    color: ${({ color }) => color};
    background: ${({ hoverColor }) => hoverColor};
  }
`;
const HeadingContainerRow = styled(Row)`
  padding: 20px 0;
`;
const HeadlineText = styled.div`
  font-size: 36px;
  line-height: 1.3em;
  color: ${({ color }) => color};
  font-family: "Golos Text", sans-serif !important;
  @media (max-width: 481px) {
    font-size: 22px;
  }
`;
const SubHeadlineText = styled.div`
  font-size: 23px;
  line-height: normal;
  margin-top: 5px;
  color: ${({ color }) => color};
  font-family: "Open Sans", Helvetica, sans-serif !important;
  @media (max-width: 481px) {
    font-size: 22px;
    padding: 0 20px;
  }
`;
const HeroContainerRow = styled(Row)`
  padding: 20px 0;
`;
const HeroContentCol = styled(Col)`
  @media (max-width: 481px) {
    margin-top: 25px;
  }
`;
const HeroContentContainer = styled.div`
  padding: 0 10px;
`;
const EditorContent = styled.div`
  * {
    color: ${({ color }) => color || "#fff"};
    font-size: 22px;
    line-height: 1.3em;
    margin: 0 !important;
    padding: 0 !important;
    white-space: pre-wrap !important;
    font-family: "Open Sans", Helvetica, sans-serif !important;
  }
`;
const HeroContentPoints = styled.div`
  margin-top: 30px;
  color: ${({ color }) => color || "#fff"};
`;
const PointListItem = styled.li`
  font-size: 17px;
  padding-bottom: 6px;
  list-style-type: none;
  margin-bottom: 6px;
`;
const PointListText = styled.div`
  @media (max-width: 481px) {
    margin-top: -5px;
  }
`;
const MedalIcon = styled(FontAwesomeIcon)`
  color: ${({ color }) => color || "#c7101b"};
`;
const CTAContainer = styled.div`
  margin-top: 25px;
`;
const CTAButton = styled.button`
  font-size: 20px;
  padding: 10px 25px;
  border-radius: 3px;
  color: ${({ color }) => color};
  background: ${({ bg }) => bg};
  &:hover {
    color: ${({ color }) => color};
  }
  @media (max-width: 481px) {
    font-size: 16px;
  }
`;
const HeroImg = styled.img`
  max-width: 100%;
  height: 500px;
  object-fit: cover;
`;
const BenefitsRowContainer = styled(Row)`
  padding: 20px 0;
`;
const BenefitsInnerCol = styled(Col)`
  padding: 0 10px;
  @media (max-width: 481px) {
    padding: 0 5px;
  }
`;
const HeroBenefitsSeparatorWrapper = styled.div`
  min-height: 1px;
`;
const HeroBenefitsSeparator = styled.div`
  padding-bottom: 10px;
  border-top: 1px solid rgb(199, 16, 27);
`;
const BenefitsHeading = styled.div`
  font-size: 32px;
  margin-top: 20px;
  line-height: 1.3em;
  color: ${({ color }) => color};
  font-family: "Golos Text", sans-serif !important;
`;
const BenefitContentWrapper = styled.div`
  font-size: 15px;
  border-bottom: 1px solid #eee;
  @media (max-width: 481px) {
    font-size: 14px;
  }
`;
const BenefitContentRow = styled(Row)`
  padding-bottom: 20px;
`;
const BenefitIconWrapper = styled.div`
  font-size: 28px;
  margin: 5px 10px 2px 0;
`;
const CTAAfterBenefitContainer = styled.div`
  margin-top: 40px;
  padding-bottom: 20px;
`;
const MapContainer = styled.div`
  padding-top: 25px;
  padding-bottom: 20px;
  @media (max-width: 481px) {
    margin-top: 20px;
    padding-bottom: 50px;
  }
`;
const IframeContainer = styled.div`
  padding: 0 10px;
  @media (max-width: 481px) {
    padding: 0;
  }
`;
const MapIframe = styled.div`
  iframe {
    width: 100% !important;
    height: 350px !important;
  }
`;
const FooterContainer = styled.div`
  margin-top: 100px;
  padding: 20px 0;
`;
const FooterInner = styled.div`
  width: 1170px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
`;
const FooterRow = styled(Row)`
  padding-top: 20px;
  padding-bottom: 20px;
`;
const FooterColumn = styled(Col)`
  margin-top: 50px;
`;
const CompanyColumn = styled.div`
  color: ${({ color }) =>
    color === FOOTER_FOREGROUND.SHADOWED ? "#fff" : color};
`;
const CompanyName = styled.span`
  font-size: 12px;
  line-height: 1.3em;
`;
const FooterAnchorsContainer = styled.div`
  padding: 0 10px;
  font-size: 12px;
  line-height: 1.3em;
`;

const BenefitLabel = styled.div`
  color: ${({ color }) => color};
`;

export {
  Header,
  HeroImg,
  HeaderRow,
  HeaderImg,
  PhNumLink,
  MedalIcon,
  CTAButton,
  MapIframe,
  FooterRow,
  CompanyName,
  FooterInner,
  HeadlineText,
  ContactUsBtn,
  CTAContainer,
  MapContainer,
  FooterColumn,
  CompanyColumn,
  WideContainer,
  CenterContent,
  PhNumHeadline,
  EditorContent,
  PointListItem,
  PointListText,
  InnerContainer,
  HeroContentCol,
  SubHeadlineText,
  BenefitsHeading,
  IframeContainer,
  FooterContainer,
  HeroContainerRow,
  BenefitsInnerCol,
  HeroContentPoints,
  BenefitContentRow,
  MarginTopContainer,
  BenefitIconWrapper,
  HeadingContainerRow,
  HeroContentContainer,
  BenefitsRowContainer,
  TemplateTwoBackground,
  HeroBenefitsSeparator,
  BenefitContentWrapper,
  FooterAnchorsContainer,
  CTAAfterBenefitContainer,
  HeroBenefitsSeparatorWrapper,
  BenefitLabel
};
