import { useSelector } from "react-redux";
import {
  Main,
  Header,
  HeaderImg,
  HeroImage,
  Separator,
  GridCover,
  EmojiCover,
  PolicyText,
  FooterInner,
  FooterPadder,
  BenefitLabel,
  PageHeadline,
  ContentInner,
  EditorContent,
  PolicySection,
  SecondHeadline,
  BenefitSubtext,
  ImageContainer,
  SeparatorInner,
  FooterContainer,
  BenefitTextCover,
  BenefitsHeadline,
  ContentContainer,
  PolicyTextDivider,
  HeadingsContainer,
  HeroImageContainer,
  SpearatorContainer,
  HeroContentContainer,
  BenefitsGridContainer,
  FooterEditorContainer,
  BenefitsHeadingsContainer
} from "./styles";
import CTABtnComponent from "./ctaButton";
import { THUMBNAIL_TYPE } from "../../../../templates/strings";
import AnchorElement from "../../../CommonVariantComponents/anchor-element";
import useCheckMobileScreen from "../../../../hooks/useCheckIsMobileScreen";

const TemplateOne = () => {
  const isMobileScreenDetected = useCheckMobileScreen();
  const { funnel } = useSelector((state) => state.funnelInfo);
  const { design, companyInfo, landingPageId: landingPage } = funnel;
  const {
    imprintUrl,
    imprintContent,
    dataProtectionUrl,
    dataProtectionContent
  } = companyInfo;

  return (
    <Main>
      <Header
        className="p-3 d-flex align-items-center justify-content-center"
        bg={landingPage.headerBackgroundColor}
      >
        <HeaderImg
          alt="company-logo"
          src={`${process.env.REACT_APP_ASSET_CDN_URL}/${design.companyLogo?.key}/${THUMBNAIL_TYPE.COMPANY_LOGO}`}
        />
      </Header>
      <ContentContainer>
        <ContentInner>
          <HeadingsContainer className="fw-bold text-center">
            <PageHeadline color={landingPage.headlineColor}>
              {landingPage.headlineText}
            </PageHeadline>
            <SecondHeadline color={landingPage.heroHeadlineColor}>
              {landingPage.heroHeadline}
            </SecondHeadline>
          </HeadingsContainer>
        </ContentInner>
        <div className="mw-100 w-100">
          <ImageContainer className="mx-auto">
            <HeroImageContainer>
              <div className="overflow-hidden">
                <HeroImage
                  src={`${process.env.REACT_APP_ASSET_CDN_URL}/${landingPage.heroImage.key}/${THUMBNAIL_TYPE.BACKGROUND}`}
                  alt="hero-image"
                />
              </div>
            </HeroImageContainer>
          </ImageContainer>
          <HeroContentContainer>
            <ContentInner>
              <div className="text-center">
                <EditorContent
                  dangerouslySetInnerHTML={{
                    __html: landingPage.heroContent
                  }}
                />
              </div>
            </ContentInner>
          </HeroContentContainer>
        </div>
        <CTABtnComponent />
        <SpearatorContainer className="w-100">
          <ContentInner>
            <SeparatorInner>
              <Separator />
            </SeparatorInner>
          </ContentInner>
        </SpearatorContainer>
        <BenefitsHeadingsContainer className="text-center">
          <ContentInner>
            <BenefitsHeadline
              className="fw-bold"
              color={landingPage.benefitsGridHeadlineColor}
            >
              {landingPage.benefitsGridHeadline}
            </BenefitsHeadline>
            <SecondHeadline
              className="fw-bold"
              color={landingPage.benefitsGridHeadlineColor}
            >
              {landingPage.benefitsGridSubHeadline}
            </SecondHeadline>
          </ContentInner>
        </BenefitsHeadingsContainer>
        <BenefitsGridContainer>
          <ContentInner>
            <GridCover className="d-grid gap-4 text-start">
              {landingPage.benefitsGrid.map((item, benefitIdx) => (
                <div
                  key={benefitIdx}
                  className="w-100 d-flex align-items-start align-top"
                >
                  <EmojiCover className="d-flex flex-shrink-0 align-items-center justify-content-center">
                    {item.icon}
                  </EmojiCover>
                  <BenefitTextCover className="flex-grow-1 align-items-center">
                    <div>
                      <div>
                        <BenefitLabel
                          className="fw-bold"
                          color={landingPage.benefitsGridTextColor}
                        >
                          {item.label}
                        </BenefitLabel>
                      </div>
                      <div>
                        <BenefitSubtext
                          color={landingPage.benefitsGridTextColor}
                        >
                          {item.subText}
                        </BenefitSubtext>
                      </div>
                    </div>
                  </BenefitTextCover>
                </div>
              ))}
            </GridCover>
          </ContentInner>
        </BenefitsGridContainer>
        {isMobileScreenDetected && <CTABtnComponent />}
        <FooterContainer className="mx-auto">
          <FooterPadder>
            <ContentInner>
              <FooterInner>
                <FooterEditorContainer>
                  <EditorContent
                    dangerouslySetInnerHTML={{
                      __html: landingPage.footerContent
                    }}
                  />
                </FooterEditorContainer>
              </FooterInner>
            </ContentInner>
          </FooterPadder>
        </FooterContainer>
        <PolicySection>
          <ContentInner>
            <PolicyText className="d-flex align-items-center justify-content-center">
              <AnchorElement
                fs="12"
                shouldUnderline
                color="#00000080"
                anchorText="Impressum"
                modalData={{
                  heading: "Impressum",
                  content: imprintContent
                }}
                href={imprintContent ? void 0 : imprintUrl}
                footerForeground={design?.footerForeground}
              />
              <PolicyTextDivider>·</PolicyTextDivider>
              <AnchorElement
                fs="12"
                shouldUnderline
                color="#00000080"
                anchorText="Datenschutzerklärung"
                modalData={{
                  heading: "Datenschutzerklärung",
                  content: dataProtectionContent
                }}
                href={dataProtectionContent ? void 0 : dataProtectionUrl}
                footerForeground={design?.footerForeground}
              />
            </PolicyText>
          </ContentInner>
        </PolicySection>
      </ContentContainer>
    </Main>
  );
};
export default TemplateOne;
