import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

const PageNotFound = () => {
  return (
    <div className="m-3">
      <Card className="border-danger text-danger">
        <Card.Body>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faCircleExclamation} className="me-2" />
            <div>
              <span className="fw-bold">Seite nicht gefunden. </span>
              <small>Diese Domain wurde offline genommen.</small>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};
export default PageNotFound;
