import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { faMedal } from "@fortawesome/free-solid-svg-icons";
import {
  Header,
  HeroImg,
  HeaderRow,
  HeaderImg,
  PhNumLink,
  MedalIcon,
  MapIframe,
  FooterRow,
  CompanyName,
  FooterInner,
  HeadlineText,
  ContactUsBtn,
  CTAContainer,
  MapContainer,
  FooterColumn,
  WideContainer,
  CenterContent,
  PhNumHeadline,
  EditorContent,
  PointListItem,
  PointListText,
  CompanyColumn,
  InnerContainer,
  HeroContentCol,
  SubHeadlineText,
  BenefitsHeading,
  IframeContainer,
  FooterContainer,
  HeroContainerRow,
  BenefitsInnerCol,
  HeroContentPoints,
  BenefitContentRow,
  MarginTopContainer,
  HeadingContainerRow,
  HeroContentContainer,
  BenefitsRowContainer,
  TemplateTwoBackground,
  HeroBenefitsSeparator,
  FooterAnchorsContainer,
  CTAAfterBenefitContainer,
  HeroBenefitsSeparatorWrapper
} from "./styles";
import {
  THUMBNAIL_TYPE,
  MOBILE_BACKGROUND_WIDTH
} from "../../../../templates/strings";
import CTAButtonComponent from "./ctaButton";
import BenefitsColumn from "./benefits-column";
import AnchorElement from "../../../CommonVariantComponents/anchor-element";
import { setRecordActionAndHasLandingPageStates } from "../../../../store/slices/funnelInfo";

const TemplateTwo = () => {
  const dispatch = useDispatch();
  const isMobileBackground =
    window.document.body.offsetWidth <= MOBILE_BACKGROUND_WIDTH;
  const { funnel } = useSelector((state) => state.funnelInfo);
  const { design, landingPageId: landingPage, companyInfo: company } = funnel;

  return (
    <TemplateTwoBackground
      bgImg={`${process.env.REACT_APP_ASSET_CDN_URL}/${
        landingPage.backgroundImage?.key
      }/${
        isMobileBackground
          ? THUMBNAIL_TYPE.MOBILE_BACKGROUND
          : THUMBNAIL_TYPE.BACKGROUND
      }`}
    >
      <WideContainer className="w-100 m-auto">
        <CenterContent
          className="position-relative"
          bg={landingPage.centerContentBackgroundColor}
          opacity={landingPage.centerContentBackgroundOpacity}
        >
          <InnerContainer className="position-relative">
            <Header className="text-center text-md-start">
              <HeaderRow>
                <Col xs={12} md={4} className="align-self-md-end">
                  <HeaderImg
                    alt="company-logo"
                    src={`${process.env.REACT_APP_ASSET_CDN_URL}/${design.companyLogo?.key}/${THUMBNAIL_TYPE.COMPANY_LOGO}`}
                  />
                </Col>
                <Col xs={12} md={5} className="text-md-end">
                  <MarginTopContainer>
                    <ContactUsBtn
                      className="fw-bold"
                      color={landingPage.contactUsButtonColor}
                      hoverColor={landingPage.contactUsButtonHoverBackground}
                      onClick={() => {
                        dispatch(setRecordActionAndHasLandingPageStates());
                      }}
                    >
                      {landingPage.contactUsButtonText}
                    </ContactUsBtn>
                  </MarginTopContainer>
                </Col>
                <Col xs={12} md={3} className="text-md-end">
                  <MarginTopContainer>
                    <PhNumHeadline color={landingPage.phoneNumberColor}>
                      {landingPage.phoneNumberHeadline}
                    </PhNumHeadline>
                    <div>
                      <PhNumLink
                        className="fw-bold"
                        href={`tel:${landingPage.phoneNumberText}`}
                        color={landingPage.phoneNumberColor}
                      >
                        {landingPage.phoneNumberText}
                      </PhNumLink>
                    </div>
                  </MarginTopContainer>
                </Col>
              </HeaderRow>
            </Header>
            <HeadingContainerRow>
              <Col xs={12}>
                <HeadlineText
                  color={landingPage.headlineColor}
                  className="text-center fw-bold text-decoration-underline"
                >
                  {landingPage.headlineText}
                </HeadlineText>
                <SubHeadlineText
                  className="text-center"
                  color={landingPage.subHeadlineColor}
                >
                  {landingPage.subHeadlineText}
                </SubHeadlineText>
              </Col>
            </HeadingContainerRow>
            <HeroContainerRow>
              <Col xs={12} md={7}>
                <HeroContentContainer className="text-white">
                  <EditorContent
                    color={landingPage.heroContentTextColor}
                    dangerouslySetInnerHTML={{
                      __html: landingPage.heroContent
                    }}
                  />
                  <HeroContentPoints color={landingPage.heroContentTextColor}>
                    <ul className="ps-0">
                      {landingPage.heroContentPoints.map((item) => (
                        <PointListItem
                          key={item.position}
                          className="d-flex align-items-md-center"
                        >
                          <MedalIcon
                            className="me-3"
                            icon={faMedal}
                            color={landingPage.heroContentIconColor}
                          />
                          <PointListText>
                            <b>{item.label}:</b>
                            <span className="ms-1">{item.text}</span>
                          </PointListText>
                        </PointListItem>
                      ))}
                    </ul>
                  </HeroContentPoints>
                  <CTAContainer className="text-center">
                    <CTAButtonComponent />
                  </CTAContainer>
                </HeroContentContainer>
              </Col>
              <HeroContentCol xs={12} md={5}>
                <HeroContentContainer>
                  <HeroImg
                    alt="hero-img"
                    src={`${process.env.REACT_APP_ASSET_CDN_URL}/${landingPage.heroImage?.key}/${THUMBNAIL_TYPE.BACKGROUND}`}
                  />
                </HeroContentContainer>
              </HeroContentCol>
            </HeroContainerRow>
            <BenefitsRowContainer>
              <Col xs={12}>
                <BenefitsInnerCol xs={12}>
                  <HeroBenefitsSeparatorWrapper className="w-100">
                    <HeroBenefitsSeparator />
                    <BenefitsHeading
                      color={landingPage.benefitsGridHeadlineColor}
                      className="fw-bold text-center text-decoration-underline"
                    >
                      {landingPage.benefitsGridHeadline}
                    </BenefitsHeading>
                  </HeroBenefitsSeparatorWrapper>
                </BenefitsInnerCol>
              </Col>
            </BenefitsRowContainer>
            <BenefitContentRow>
              <Col xs={12} md={6}>
                {landingPage.benefitsGrid
                  .slice(0, Math.ceil(landingPage.benefitsGrid.length / 2))
                  .map((item) => (
                    <BenefitsColumn
                      color={landingPage.benefitsGridTextColor}
                      key={item.position}
                      item={item}
                    />
                  ))}
              </Col>
              <Col xs={12} md={6}>
                {landingPage.benefitsGrid
                  .slice(Math.ceil(landingPage.benefitsGrid.length / 2))
                  .map((item) => (
                    <BenefitsColumn
                      color={landingPage.benefitsGridTextColor}
                      key={item.position}
                      item={item}
                    />
                  ))}
              </Col>
            </BenefitContentRow>
            <CTAAfterBenefitContainer className="text-center">
              <CTAButtonComponent />
            </CTAAfterBenefitContainer>
            <MapContainer>
              <Row className="justify-content-md-center">
                <Col xs={12} md={10}>
                  <IframeContainer>
                    <MapIframe
                      dangerouslySetInnerHTML={{
                        __html: landingPage.footerGoogleMapIframeUrl
                      }}
                    />
                  </IframeContainer>
                </Col>
              </Row>
            </MapContainer>
          </InnerContainer>
        </CenterContent>
      </WideContainer>
      <FooterContainer>
        <FooterInner>
          <FooterRow className="w-100">
            <FooterColumn xs={12} md={4}>
              <CompanyColumn
                color={landingPage.footerForeground}
                className="d-flex align-items-center justify-content-center"
              >
                ©
                <CompanyName className="ms-1 fw-bold">
                  {company?.companyName}
                </CompanyName>
              </CompanyColumn>
            </FooterColumn>
            <Col xs={0} md={4}></Col>
            <FooterColumn className="text-end" xs={12} md={4}>
              <FooterAnchorsContainer className="d-flex justify-content-center">
                <AnchorElement
                  classes="me-4"
                  anchorText="Datenschutz"
                  modalData={{
                    heading: "Datenschutzerklärung",
                    content: company?.dataProtectionContent
                  }}
                  href={
                    company?.dataProtectionContent
                      ? void 0
                      : company?.dataProtectionUrl
                  }
                  footerForeground={landingPage.footerForeground}
                />
                <AnchorElement
                  classes="ms-4"
                  anchorText="Impressum"
                  modalData={{
                    heading: "Impressum",
                    content: company?.imprintContent
                  }}
                  href={company?.imprintContent ? void 0 : company?.imprintUrl}
                  footerForeground={landingPage.footerForeground}
                />
              </FooterAnchorsContainer>
            </FooterColumn>
          </FooterRow>
        </FooterInner>
      </FooterContainer>
    </TemplateTwoBackground>
  );
};
export default TemplateTwo;
