import {
  BenefitsInnerCol,
  BenefitIconWrapper,
  BenefitContentWrapper,
  BenefitLabel
} from "./styles";

const BenefitsColumn = ({ item, color }) => {
  return (
    <BenefitsInnerCol xs={12}>
      <BenefitContentWrapper className="d-flex align-items-center text-white">
        <BenefitIconWrapper>{item.icon}</BenefitIconWrapper>
        <BenefitLabel color={color} className="fw-bold">
          {item.label}
        </BenefitLabel>
      </BenefitContentWrapper>
    </BenefitsInnerCol>
  );
};
export default BenefitsColumn;
